import React from 'react'
import { Link } from 'gatsby'
import {
	Grid,
	Container,
	Segment,
	Divider,
	List,
	Button,
} from 'semantic-ui-react'
import './pricingcontent.css.less'
import priceBg1 from '../../images/extension.png'
import docs from '../../images/pricing/docs.png'
import virtualLibrary from '../../images/pricing/virtual_library.png'
import libBookmark from '../../images/pricing/library-bookmark.png'
import searchCases from '../../images/pricing/searchcases.png'
import mlwAssist from '../../images/pricing/mlwassist-icon.png'
import summaries from '../../images/pricing/summaries.png'
import context from '../../images/pricing/context.jpg'
import microscope from '../../images/pricing/research_microscope.png'
import singleUser from '../../images/pricing/single-user.png'
import NonLawyer from '../../images/pricing/nonlawyer.png'
import lawyer from '../../images/pricing/lawyer.png'

const PricingContent = () => {
	return (
		<div className="pricing-container">
			<div className="color-overlay-pricing"></div>
			<Container>
				<img src={priceBg1} alt="" className="bg-img1-pricing" />
				<Grid stackable columns="3">
					<Grid.Row className="pricing-content">
						<Grid.Column>
							<h2 style={{ color: 'white', fontWeight: 'bold' }}>
								CHOOSE
								<br />
								YOUR PLAN
							</h2>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row stretched>
						<Grid.Column>
							<Segment.Group
								style={{
									borderRadius: '30px 30px 30px 30px',
									display: 'grid',
								}}
							>
								<Segment
									raised
									style={{
										borderRadius: '30px 30px 0 0',
										background: '#1882B5',
										color: 'white',
									}}
								>
									<h5 className="plan-title">Students</h5>
								</Segment>
								<Segment raised style={{ borderRadius: '0 0 30px 30px' }}>
									<span className="plan-subtext">Month-to-month</span>
									<h5 className="plan-price">
										₱ 2,500<sup>.00</sup>
										<sub>per month</sub>
									</h5>
									<div className="signup-link">
										<span>
											<a
												href="https://web.mylegalwhiz.com/account/sign_up"
												style={{ color: '#1882B5' }}
											>
												SIGN UP NOW
											</a>
										</span>
									</div>

									<Divider fitted />

									<span className="plan-subtext">Annual billing</span>
									<h5 className="plan-price">
										₱ 2,083<sup>.33</sup>
										<sub>per month</sub>
									</h5>

									<div className="signup-link">
										<span>
											<sup className="plan-discount"> Save ₱5,000.00</sup>
											<br />
											<a
												href="https://web.mylegalwhiz.com/account/sign_up"
												style={{ color: '#1882B5' }}
											>
												SIGN UP NOW
											</a>
										</span>
									</div>

									<span className="plan-description student">
										<strong>
											*Billed as one time payment of{' '}
											<span className="price"> ₱25,000.00</span>
										</strong>
									</span>
									<Divider fitted />
									<List className="plan-description student">
										<List.Item>
											<img src={singleUser} style={{ width: '2em' }} alt="" />
											Single, non-simultaneous access
										</List.Item>
										<List.Item>
											<img src={docs} style={{ width: '2em' }} alt="" />
											Legal and Business Forms
										</List.Item>

										<List.Item>
											<img
												src={virtualLibrary}
												style={{ width: '2em' }}
												alt=""
											/>
											Knowledge research articles and definitions
										</List.Item>
										<List.Item>
											<img src={libBookmark} style={{ width: '2em' }} alt="" />
											Library and Bookmarking System
										</List.Item>
										<List.Item>
											<img src={searchCases} style={{ width: '2em' }} alt="" />
											Cases and References
										</List.Item>
										<List.Item>
											<img src={mlwAssist} style={{ width: '2em' }} alt="" />
											AskLEA Legal Terms and Concepts
										</List.Item>
										<List.Item>
											<img src={summaries} style={{ width: '2em' }} alt="" />
											AskLEA Request for Case Summaries
										</List.Item>
									</List>
									<br />
									<br />
									<br />
								</Segment>
							</Segment.Group>
						</Grid.Column>
						<Grid.Column>
							<Segment.Group
								style={{
									borderRadius: '30px 30px 30px 30px',
									display: 'grid',
								}}
							>
								<Segment
									style={{
										borderRadius: '30px 30px 0 0',
										background: '#5BBF50',
										color: 'white',
									}}
								>
									<h5 className="plan-title">Business</h5>
								</Segment>
								<Segment style={{ borderRadius: '0 0 30px 30px' }}>
									<span className="plan-subtext">Month-to-month</span>
									<h5 className="plan-price">
										₱ 4,000<sup>.00</sup>
										<sub>per month</sub>
									</h5>

									<div className="signup-link">
										<span>
											<a
												href="https://web.mylegalwhiz.com/account/sign_up"
												style={{ color: '#5BBF50' }}
											>
												SIGN UP NOW
											</a>
										</span>
									</div>

									<Divider fitted />

									<span className="plan-subtext">Annual billing</span>
									<h5 className="plan-price">
										₱ 3,333<sup>.33</sup>
										<sub>per month</sub>
									</h5>
									<div className="signup-link">
										<span>
											<sup className="plan-discount">Save ₱8,000.00</sup>
											<br />
											<a
												href="https://web.mylegalwhiz.com/account/sign_up"
												style={{ color: '#5BBF50' }}
											>
												SIGN UP NOW
											</a>
										</span>
									</div>
									<span className="plan-description business">
										<strong>
											*Billed as one time payment of{' '}
											<span className="price"> ₱40,000.00</span>
										</strong>
									</span>
									<Divider fitted />
									<List className="plan-description business">
										<List.Item>
											<img src={singleUser} style={{ width: '2em' }} alt="" />
											Single, non-simultaneous access
										</List.Item>
										<List.Item>
											<img src={docs} style={{ width: '2em' }} alt="" />
											Legal and Business Forms
										</List.Item>
										<List.Item>
											<img
												src={virtualLibrary}
												style={{ width: '2em' }}
												alt=""
											/>
											Knowledge research articles and definitions
										</List.Item>
										<List.Item>
											<img src={mlwAssist} style={{ width: '2em' }} alt="" />
											AskLEA Legal Terms and Concepts
										</List.Item>
										<List.Item>
											<img src={context} style={{ width: '2em' }} alt="" />
											AskLEA Drafting Assistance
										</List.Item>
										<List.Item>
											<img src={microscope} style={{ width: '2em' }} alt="" />
											AskLEA Legal Research Service
										</List.Item>
										<List.Item>
											<img src={lawyer} style={{ width: '2em' }} alt="" />
											Referral to a legal expert
										</List.Item>
									</List>
									<br />
									<br />

									<br />
								</Segment>
							</Segment.Group>
						</Grid.Column>
						<Grid.Column>
							<Segment.Group
								style={{
									borderRadius: '30px 30px 30px 30px',
									display: 'grid',
								}}
							>
								<Segment
									style={{
										borderRadius: '30px 30px 0 0',
										background: '#e0ae3f',
										color: 'white',
									}}
								>
									<h5 className="plan-title">Professional</h5>
								</Segment>
								<Segment style={{ borderRadius: '0 0 30px 30px' }}>
									<span className="plan-subtext">Month-to-month</span>
									<h5 className="plan-price">
										₱ 5,000<sup>.00</sup>
										<sub>per month</sub>
									</h5>

									<div className="signup-link">
										<span>
											<a
												href="https://web.mylegalwhiz.com/account/sign_up"
												style={{ color: '#e0ae3f' }}
											>
												SIGN UP NOW
											</a>
										</span>
									</div>

									<Divider fitted />

									<span className="plan-subtext">Annual billing</span>
									<h5 className="plan-price">
										₱ 4,166<sup>.67</sup>
										<sub> per month</sub>
									</h5>

									<div className="signup-link">
										<span>
											<sup className="plan-discount">Save ₱10,000.00</sup>
											<br />
											<a
												href="https://web.mylegalwhiz.com/account/sign_up"
												style={{ color: '#e0ae3f' }}
											>
												SIGN UP NOW
											</a>
										</span>
									</div>

									<span className="plan-description professional">
										<strong>
											*Billed as one time payment of{' '}
											<span className="price"> ₱50,000.00</span>
										</strong>
									</span>
									<Divider fitted />
									<List className="plan-description professional">
										<List.Item>
											<img src={singleUser} style={{ width: '2em' }} alt="" />
											Single, non-simultaneous access
										</List.Item>
										<List.Item>
											<img src={docs} style={{ width: '2em' }} alt="" />
											Legal and Business Forms
										</List.Item>
										<List.Item>
											<img
												src={virtualLibrary}
												style={{ width: '2em' }}
												alt=""
											/>
											Knowledge research articles and definitions
										</List.Item>
										<List.Item>
											<img src={libBookmark} style={{ width: '2em' }} alt="" />
											Library and Bookmarking System
										</List.Item>
										<List.Item>
											<img src={searchCases} style={{ width: '2em' }} alt="" />
											Cases and References
										</List.Item>
										<List.Item>
											<img src={mlwAssist} style={{ width: '2em' }} alt="" />
											AskLEA Legal Terms and Concepts
										</List.Item>
										<List.Item>
											<img src={summaries} style={{ width: '2em' }} alt="" />
											AskLEA Request for Case Summaries
										</List.Item>
										<List.Item>
											<img src={context} style={{ width: '2em' }} alt="" />
											AskLEA Drafting Assistance
										</List.Item>
										<List.Item>
											<img src={microscope} style={{ width: '2em' }} alt="" />
											AskLEA Legal Research Service
										</List.Item>

										<List.Item>
											<img src={NonLawyer} style={{ width: '2em' }} alt="" />
											Referral to business and non-lawyer subscribers
										</List.Item>
									</List>
								</Segment>
							</Segment.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
			<section className="pricing-section-content">
				<div className="ui equal width grid">
					<div className="column">
						<a
							href="https://web.mylegalwhiz.com/account/sign_up"
							target="blank"
						>
							<Button className="btn-request">
								<span style={{ fontSize: '1.5em' }}>Subscribe</span>
							</Button>
						</a>
					</div>
					<div className="column">
						<a href="https://credits.mylegalwhiz.com/en/" target="blank">
							<Button className="btn-request">
								<span style={{ fontSize: '1.5em' }}>Explore with Credits</span>
							</Button>
						</a>
					</div>
				</div>
			</section>
			<div className="section-pricing-content-2">
				<div className="ui container">
					<div className="ui equal width stackable grid">
						<div className="column">
							<strong>
								<h4 className="text-highlight">
									For institutional or <br /> group subscriptions
								</h4>
							</strong>
						</div>
						<div className="column">
							<span>
								Contact our representative: <br />
								+63 2 966 8633
								<br />
								subscribe@mylegalwhiz.com
								<br />
								<br />
								<Link to="/contact">GET IN TOUCH ▼</Link>
							</span>
						</div>
						{/* <img src={mslantGlass} alt="" className="bus-mslantGlass" /> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PricingContent
