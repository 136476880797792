import React, { useState } from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import './carousel.css.less'
import BusinessContent from '../pages/partials/businesscontent.js'
import ProfessionalContent from '../pages/partials/professioanalcontent.js'
import PricingContent from '../pages/partials/pricingcontent'

import DownArrow from '../images/down-arrow.png'
import { Link } from 'gatsby'
const HideHome = () => {
	const homecontent = document.getElementById('home-section-id')
	// window.scrollBy(0, window.innerHeight / 1.5)
	const hiddensection = document.getElementById('hidden-home-sections')
	// if (
	// 	homecontent.style.display === 'none' &&
	// 	hiddensection.style.display === 'block'
	// ) {
	// 	hiddensection.style.display = 'none'
	// 	homecontent.style.display = 'block'
	// 	// hiddensection.style.marginTop = "0";
	// } else {
	hiddensection.style.display = 'block'
	homecontent.style.display = 'none'
	// hiddensection.style.marginTop = "6%";
	// }
}

function NextArrow(props) {
	const { className, style, onClick } = props
	return (
		<div
			className={(className, 'carousel-next-arrow')}
			style={{ ...style }}
			onClick={onClick}
		>
			<img
				src={DownArrow}
				alt=""
				style={{ width: '3.5em', cursor: 'pointer' }}
			/>
		</div>
	)
}

const Carousel = () => {
	const [HideBus, setHideBus] = useState(false)
	const [HidePro, setHidePro] = useState(false)
	const [HidePri, setHidePri] = useState(false)

	// console.log(hidden);
	var settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		// arrows: false,
		nextArrow: <NextArrow />,
		dotsClass: 'vertical-dots',
		adaptiveHeight: true,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		// fade: true,
		vertical: true,
		verticalSwiping: true,
	}

	return (
		<div>
			<div className="carousel-container">
				<Slider {...settings} id="slider">
					<div className="slide slide1">
						<div className="slide-content first-slide">
							<h1 className="home-description">
								The future of <i>legal research</i> is here
							</h1>
							<br />
							<br />

							<a href="/">
								<Link to="/pricing">
									<span className="home-link">ARE YOU READY ▼</span>
								</Link>
							</a>
						</div>
					</div>
					<div className="slide slide2">
						<div className="slide-content">
							<h1 className="home-description">
								Access vital <br /> legal knowledge,
								<br /> <i>anywhere</i>
							</h1>
							<br />
							<br />
							<Link to="/pricing">
								<span className="home-link">FIND OUT HOW ▼</span>
							</Link>
						</div>
					</div>
					<div className="slide slide3">
						<div className="slide-content">
							<h1 className="home-description">
								<i>
									Gain better <br />
									understanding{' '}
								</i>
								<br />
								of complex cases <br /> and legal terms
							</h1>
							<br />
							<br />

							<span
								className="home-link"
								onClick={() => {
									setHideBus(false)
									setHidePro(false)
									setHidePri(true)
									HideHome()
								}}
								onKeyDown={() => {
									setHideBus(false)
									setHidePro(false)
									setHidePri(true)
									HideHome()
								}}
							>
								SUBSCRIBE NOW ▼
							</span>
						</div>
					</div>
					<div className="slide slide4">
						<div className="slide-content">
							<h1 className="home-description">
								Have more time on <br />
								other activities <br />
								when you{' '}
								<i>
									save <br />
									time
								</i>{' '}
								on research
							</h1>
							<br />
							<br />

							<Link to="/pricing">
								<span className="home-link">ARE YOU READY ▼</span>
							</Link>
						</div>
					</div>
					<div className="slide slide5">
						<div className="slide-content">
							<h1 className="home-description">
								Get{' '}
								<i>
									vetted,
									<br />
									customizable,
								</i>{' '}
								<br /> legal and
								<br /> business forms
							</h1>
							<br />
							<br />
							<span
								className="home-link"
								onClick={() => {
									setHideBus(true)
									setHidePro(false)
									setHidePri(false)
									HideHome()
								}}
								onKeyDown={() => {
									setHideBus(true)
									setHidePro(false)
									setHidePri(false)
									HideHome()
								}}
							>
								GET STARTED ▼
							</span>
						</div>
					</div>
					<div className="slide slide6">
						<div className="slide-content">
							<h1 className="home-description">
								<i>Save money</i> <br />
								on photocopies
								<br /> and outdated <br />
								books
							</h1>
							<br />
							<br />
							<span
								className="home-link"
								onClick={() => {
									setHideBus(false)
									setHidePro(true)
									setHidePri(false)
									HideHome()
								}}
								onKeyDown={() => {
									setHideBus(false)
									setHidePro(true)
									setHidePri(false)
									HideHome()
								}}
							>
								GET STARTED ▼
							</span>
						</div>
					</div>
				</Slider>
			</div>
			<div id="hidden-home-sections">
				{HidePri && <PricingContent />}
				{HideBus && <BusinessContent />}
				{HidePro && <ProfessionalContent />}
			</div>
		</div>
	)
}

export default Carousel
