import React from 'react'
import cpLea from '../images/cpleahome.png'
// import clientLogos from '../images/clientLogos.jpg'
import { Link, graphql, useStaticQuery } from 'gatsby'
import './index.css.less'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/carousel.js'
import leaText from '../assets/images/lea-text.png'
import appleIcon from '../assets/images/apple-icon.png'
import leaTablet from '../assets/images/lea-tablet.png'

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query Image {
			image: file(relativePath: { eq: "Logos.png" }) {
				id
				childImageSharp {
					fluid(maxWidth: 5000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`)

	return (
		<Layout>
			<Seo title="Home" />
			<Carousel />
			<div className="home-sections" id="home-section-id">
				<div className="ui equal width grid">
					<section className="row section-1">
						<div className="six wide column">
							<img src={cpLea} alt="" className="cp-lea-home" />
						</div>
						<div className="column section-1">
							<img className="ui big image" src={leaText}></img>
							<br />
							<img className="ui medium image apple-icon" src={appleIcon}></img>
						</div>
					</section>
				</div>
				<section className="section-2">
					<div className="ui equal width grid stackable">
						<div className="row">
							<div className="nine wide column">
								<h2 className="home-title">
									Empowering everyone with <br />
									legal knowledge, assistance <br />
									and protection
								</h2>
								<br />
								<Link to="/pricing">
									<button className="ui huge primary button">
										Sign up now
									</button>
								</Link>
							</div>
							<div className="column lea-tablet-col">
								{/* <img
									src={leaTablet}
									className="ui fluid image lea-tablet"
								></img> */}
							</div>
						</div>
					</div>
				</section>
				<section className="section-3">
					<div className="ui container">
						<h2>Who uses MyLegalWhiz?</h2>
						<div style={{ maxWidth: '50em', margin: '0 auto' }}>
							<Img fluid={data.image.childImageSharp.fluid} />
						</div>
					</div>
				</section>
			</div>
		</Layout>
	)
}

export default IndexPage
